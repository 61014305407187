import React from 'react';
import PropTypes from 'prop-types';
import InputNumber from '../../../general/InputNumber';
import FormOptionTypeSelect from '../../../project/FormOptionTypeSelect';
import ParametersSelect from '../../../project/ParametersSelect';
import ProductSelect from '../../../project/ProductSelect';
import InputText from '../../../general/InputText';
import {Trans, t} from '@lingui/macro';
import {Button, Checkbox, Radio} from 'antd';
import {
    FORM_TYPE_ENUM,
    FORM_TYPE_NUMERIC,
    FORM_TYPE_BOOL,
} from '../../../../constants/form';
import SingleFileUpload from '../../../general/SingleFileUpload';
import { checkImage, getInputOnChangeEventChecked, getInputOnChangeEventValue } from '../../../../lib/project';
import InfoAttribute from '../../../general/InfoAttribute';
import { isNumberAndGreaterThan } from '../../../../lib/number';

/**
 * @fero
 */

class OptionFormPart extends React.PureComponent {
    static propTypes = {
        formOption: PropTypes.object,
        updateOption: PropTypes.func.isRequired,
        removeOption: PropTypes.func.isRequired,
    };

    render() {
        const {formOption = {}, updateOption, removeOption} = this.props;
        return <div className="form-option-wrapper">
            <InfoAttribute
                attributeTitle={<Trans>Názov premennej:</Trans>}
                attributeValue={
                    <InputText
                        value={formOption.name}
                        onChange={(newName) => updateOption({name: {$set: newName}})}
                    />
                }
                isRequired={true}
                hasError={formOption.name == null || formOption.name.trim() == ''}
            />
            <InfoAttribute
                attributeTitle={<Trans>Popis:</Trans>}
                attributeValue={
                    <InputText
                        value={formOption.label}
                        onChange={(newLabel) => updateOption({label: {$set: newLabel}})}
                    />
                }
                isRequired={true}
                hasError={formOption.label== null || formOption.label.trim() == ''}
            />
            <InfoAttribute
                attributeTitle={<Trans>Typ:</Trans>}
                attributeValue={
                    <FormOptionTypeSelect
                        value={formOption.id_type}
                        onChange={(newType) => updateOption({id_type: {$set: newType}})}
                    />
                }
                isRequired={true}
                hasError={formOption.id_type == null}
            />
            <InfoAttribute
                attributeTitle={<Trans>Povinné:</Trans>}
                attributeValue={
                    <Radio.Group
                        value={formOption.is_required}
                        onChange={(ev) => updateOption({is_required: {$set: getInputOnChangeEventValue(ev)}})}
                    >
                        <Radio.Button value={1}><Trans>Áno</Trans></Radio.Button>
                        <Radio.Button value={0}><Trans>Nie</Trans></Radio.Button>
                    </Radio.Group>
                }
            />
            <InfoAttribute
                attributeTitle={<Trans>Parameter:</Trans>}
                attributeValue={
                    <ParametersSelect
                        value={formOption.id_parameter}
                        onChange={(newParameterId) => updateOption({id_parameter: {$set: newParameterId}})}
                    />
                }
            />
            <InfoAttribute
                attributeTitle={<Trans>Poradové číslo:</Trans>}
                attributeValue={
                    <InputNumber
                        style={{width: '10rem'}}
                        value={formOption.sequence}
                        min={0}
                        step={1}
                        onChange={(newSeq) => updateOption({sequence: {$set: newSeq}})}
                    />
                }
            />
            { formOption.id_type == FORM_TYPE_NUMERIC ? 
                <React.Fragment>
                    <InfoAttribute
                        attributeTitle={<Trans>Jednotky:</Trans>}
                        attributeValue={
                            <InputText
                                value={formOption.unit}
                                onChange={(newUnit) => updateOption({unit: {$set: newUnit}})}
                            />
                        }
                    />
                    <InfoAttribute
                        attributeTitle={<Trans>Minimum:</Trans>}
                        attributeValue={
                            <InputNumber
                                style={{width: '10rem'}}
                                value={formOption.min_val}
                                onChange={(newMinVal) => updateOption({min_val: {$set: newMinVal}})}
                            />
                        }
                    />
                    <InfoAttribute
                        attributeTitle={<Trans>Maximum:</Trans>}
                        attributeValue={
                            <InputNumber
                                style={{width: '10rem'}}
                                value={formOption.max_val}
                                onChange={(newMaxVal) => updateOption({max_val: {$set: newMaxVal}})}
                            />
                        }
                    />
                    <InfoAttribute
                        attributeTitle={<Trans>Krok (číselný):</Trans>}
                        attributeValue={
                            <InputNumber
                                style={{width: '10rem'}}
                                value={formOption.val_step}
                                onChange={(newStep) => updateOption({val_step: {$set: newStep}})}
                            />
                        }
                    />
                </React.Fragment> : null
            }
            {formOption.id_type == FORM_TYPE_BOOL ? 
                <React.Fragment>
                    <InfoAttribute
                        attributeTitle={<Trans>Komponent:</Trans>}
                        attributeValue={
                            <ProductSelect
                                value={formOption.id_component}
                                onChange={(newProductId) => updateOption({
                                    id_component: {$set: newProductId},
                                    quantity: {$set: 1},
                                    is_paid: {$set: 1},
                                })}
                            />
                        }
                    />
                    { formOption.id_component != null ? 
                        <React.Fragment>
                            <InfoAttribute
                                attributeTitle={<Trans>Množstvo:</Trans>}
                                attributeValue={
                                    <InputNumber
                                        style={{width: '10rem'}}
                                        value={formOption.quantity}
                                        onChange={(newQuantity) => updateOption({quantity: {$set: newQuantity}})}
                                        step={0.01}
                                        min={0}
                                    />
                                }
                                isRequired={true}
                                hasError={!isNumberAndGreaterThan(formOption.quantity, 0.0)}
                            />
                            <InfoAttribute
                                attributeTitle={<Trans>Spoplatnené:</Trans>}
                                attributeValue={
                                    <Radio.Group
                                        value={formOption.is_paid}
                                        onChange={(ev) => updateOption({is_paid: {$set: getInputOnChangeEventValue(ev)}})}
                                    >
                                        <Radio.Button value={1}><Trans>Áno</Trans></Radio.Button>
                                        <Radio.Button value={0}><Trans>Nie</Trans></Radio.Button>
                                    </Radio.Group>
                                }
                            />
                        </React.Fragment> : null
                    }
                </React.Fragment> : null
            }
            {formOption.id_type == FORM_TYPE_ENUM ? 
                <div className="full-size-width">
                    <h5 className="p-3 text-center">
                        <span className="mr-2 text-danger">*</span>
                        <Trans>Hodnoty:</Trans>
                    </h5>
                    <OptionValuesTable
                        values={formOption.values}
                        updateOption={updateOption}
                    />
                     <Button
                        className="my-3"
                        onClick={() => {
                            if(formOption.values != null) 
                                updateOption({values: {$push: [{}]}});
                            else
                                updateOption({values: {$set: [{}]}});
                        }}
                    >
                        <Trans>Pridať hodnotu</Trans>
                    </Button>
                </div> : null
            }
            <InfoAttribute
                attributeValue={
                    <div className="text-right">
                        <Button
                            type="danger"
                            onClick={removeOption}
                        >
                            <Trans>Vymazať možnosť</Trans>
                        </Button>
                    </div>
                }
            />
        </div>;
    }

}

export default OptionFormPart;

class OptionValuesTable extends React.PureComponent {
    static propTypes = {
        values: PropTypes.arrayOf(PropTypes.object),
        updateOption: PropTypes.func.isRequired,
    };

    static defaultProps = {
        values: [],
    };

    render() {
        const {values, updateOption} = this.props;
        return values != null && Array.isArray(values) ? 
        <table className="simple-table">
            <tbody>
            { values.map((val, key) => {
                const updateValue = (obj) => updateOption({values: {[key]: obj}});
                return <tr key={key}>
                    <td style={{width: '5rem'}}>
                        <InputNumber
                            size="small"
                            value={val.sequence}
                            min={0}
                            step={1}
                            onChange={(newSeq) => updateValue({sequence: {$set: newSeq}})}
                        />
                    </td>
                    <td>
                        <InputText
                            placeholder="hodnota"
                            size="small"
                            value={val.val}
                            onChange={(newVal) => updateValue({val: {$set: newVal}})}
                        />
                    </td>
                    <td>
                        <InputText
                            placeholder="popis"
                            size="small"
                            value={val.label}
                            onChange={(newLabel) => updateValue({label: {$set: newLabel}})}
                        />
                    </td>
                    <td>
                        <ProductSelect
                            size="small"
                            value={val.id_component}
                            onChange={(newProductId) => updateValue({
                                id_component: {$set: newProductId},
                                quantity: {$set: 1},
                                is_paid: {$set: 1}
                            })}
                        />
                    </td>
                    <td>
                        { val.id_component != null ? 
                            <div>
                                <InputNumber
                                    size="small"
                                    value={val.quantity}
                                    onChange={(newQuantity) => updateValue({quantity: {$set: newQuantity}})}
                                    min={0}
                                    step={0.01}
                                />
                                <Checkbox
                                    size="small"
                                    className="pt-2 d-flex align-items-center"
                                    checked={val.is_paid ? true : false}
                                    onChange={(ev) => {
                                        const newIsPaid = getInputOnChangeEventChecked(ev) ? 1 : 0;
                                        updateValue({is_paid: {$set: newIsPaid}});
                                    }}
                                >
                                    <Trans>spoplatnené</Trans>
                                </Checkbox>
                            </div> : null
                        }
                    </td>
                    <td>
                        <SingleFileUpload
                            size="small"
                            beforeUpload={checkImage}
                            showPreview={true}
                            value={val.thumbnail}
                            onChange={(newThumb) => updateValue({thumbnail: {$set: newThumb}})}
                        />
                    </td>
                    <td>
                        <Button
                            type="danger"
                            icon="delete"
                            size="small"
                            onClick={() => updateOption({values: {$splice: [[key, 1]]}})}
                        />
                    </td>
                </tr>;
            })}
            </tbody>
        </table> : null;
    }

}
